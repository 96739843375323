import { useUser } from "hooks/useAuth";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import UAParser from "ua-parser-js";
import { useCameras, useDisableZoom } from "../hooks";
import BinahMonitor from "./BinahMonitor";
import { Flex } from "./shared/Flex";
import { SubjectDemographic } from "@binah/web-sdk";

const Container = styled(Flex)<{ isSettingsOpen: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: ${({ isSettingsOpen }) =>
    isSettingsOpen && "rgba(0, 0, 0, 0.5)"};
`;

type Props = {
  subjectDemographic: SubjectDemographic;
};

const App = ({ subjectDemographic }: Props) => {
  const user = useUser();

  const cameras = useCameras();
  const [cameraId, setCameraId] = useState<string>();
  const [isLicenseValid, setIsLicenseValid] = useState(false);
  const [isMobile] = useState(
    UAParser(navigator.userAgent).device.type === "mobile"
  );
  useDisableZoom();

  // const onSettingsClickedHandler = useCallback((event) => {
  //   const settingsBars = document.getElementById("settingsBars");
  //   const isSettingsButtonClicked = event.target.id === "settingsButton";

  //   const isInsideSettingsClicked =
  //     settingsBars.contains(event.target as Node) || isSettingsButtonClicked;

  //   if (!isInsideSettingsClicked) {
  //     setIsSettingsOpen(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   document.addEventListener("click", onSettingsClickedHandler);
  //   return () => {
  //     document.removeEventListener("click", onSettingsClickedHandler);
  //   };
  // }, []);

  const updateLicenseStatus = useCallback((valid) => {
    setIsLicenseValid(valid);
  }, []);

  // const toggleSettingsClick = useCallback(() => {
  //   setIsSettingsOpen(!isSettingsOpen);
  // }, [isSettingsOpen]);

  // const handleCloseSettings = useCallback(({ cameraId }) => {
  //   setCameraId(cameraId);
  //   setIsSettingsOpen(false);
  // }, []);

  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);

  if (!user) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <Container isSettingsOpen={false} className="max-w-2xl mx-auto">
      <BinahMonitor
        showMonitor={!(isMobile && false)}
        cameraId={cameraId}
        onLicenseStatus={updateLicenseStatus}
        subjectDemographic={subjectDemographic}
      />
    </Container>
  );
};

export default App;
